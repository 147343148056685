import React, { useState, useContext } from "react";
import '../css/ColorSelector.css';
import { AppContext } from '../pages/AppContext';
import ColorWheel from './ColorWheel';

const ColorSelector = () => {
  const { color, setColor } = useContext(AppContext);
  
  const colors = ["#ee0000","#e41ca1", "#B8FF00", "#00cfef", "#9900ff", "#d3d300","#FFA500","#747466", "#48605f", "#604849", "#485560", "#605f48", "#434343","#9c9c9c", "#FFFFFF"];
  
  const [placeholder, setPlaceholder] = useState("#00cfef");
  const [isColorWheelOpen, setIsColorWheelOpen] = useState(false);

  const handleColorChange = (newColor) => {
    setColor(newColor);
    console.log("COLOR SET TO: " + newColor);
    setPlaceholder(newColor);
    if (typeof window.setMeshColor === "function") {
      window.setMeshColor(newColor);
    }
  };

  const handleCustomColorSubmit = (customColor) => {
    if (/^#[0-9A-F]{6}$/i.test(customColor)) {
      handleColorChange(customColor);
    } else {
      console.error("Invalid hex color format.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleCustomColorSubmit(color);
    }
  };

  return (
    <div className="color-selector">
      <div className="custom-color-input">
        <div>Armor Finish: </div>
        <input
          className="color-input-width"
          maxLength="7"
          type="text"
          value={color}
          onChange={(e) => handleColorChange(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
        />
        <button onClick={() => setIsColorWheelOpen(true)}>
          🎨
        </button>
      </div>
      <div className="color-presets">
        {colors.map((presetColor, index) => (
          <button
            key={index}
            className="color-button"
            style={{ backgroundColor: presetColor }}
            onClick={() => handleColorChange(presetColor)}
          />
        ))}
      </div>

      {isColorWheelOpen && (
        <ColorWheel
          color={color}
          onChange={handleColorChange}
          onClose={() => setIsColorWheelOpen(false)}
        />
      )}
    </div>
  );
};

export default ColorSelector;
