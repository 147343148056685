import * as THREE from 'three';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from './DRACO/DRACOLoader.js';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import trans from "../../library/poses/translate.json";
//import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { TransformControls } from "./TransformControls";
import editor from '../../Backups/Editor.js'; // import the editor module
//import MinGeometryFinder from "./MinGeometryFinder";
import MinSTLExporter from "./MinSTLExporter";
import "../../css/Sprite.css"
//import { ThirtyFpsRounded } from '@mui/icons-material';
//import { Sky } from "three-sky";
//import { Sky } from "three-addons/node_modules/three/examples/js/objects/Sky.js";
import { Sky } from "./Sky.js";
// When adding parts add to:
// MainStage.js - Selector.js - Category.js - App.js
// If it's a new bone don't forget to add it to Editor.js - model.josn - bones.json. You'll have to manually add the new bones to the poses so the part will properly position.
import bones from "../../library/bones.json";
import model from "../../library/poses/model.json";
import { gsap } from 'gsap';
import "../../css/universal/universal.css"
import { AppContext } from '../../pages/AppContext';


class MainStage {

  constructor(meshColor, renderFunction, fps = 60) {
    this.state = {
      Head_Main: { x: 0, y: 0, z: 0 },
      Neck_Main: { x: 0, y: 0, z: 0 },
      SpineHigh: { x: 0, y: 0, z: 0 },
      SpineMid: { x: 0, y: 0, z: 0 },
      SpineLow: { x: 0, y: 0, z: 0 },
      Hips_Main: { x: 0, y: 0, z: 0 },
      Root_Main: { x: 0, y: 0, z: 0 },
      RightShoulder: { x: 0, y: 0, z: 0 },
      RightArm: { x: 0, y: 0, z: 0 },
      RightForeArm: { x: 0, y: 0, z: 0 },
      RightHand: { x: 0, y: 0, z: 0 },
      RightHandThumbLow: { x: 0, y: 0, z: 0 },
      RightHandThumbMid: { x: 0, y: 0, z: 0 },
      RightHandThumbHigh: { x: 0, y: 0, z: 0 },
      RightHandIndexLow: { x: 0, y: 0, z: 0 },
      RightHandIndexMid: { x: 0, y: 0, z: 0 },
      RightHandIndexHigh: { x: 0, y: 0, z: 0 },
      RightHandMiddleLow: { x: 0, y: 0, z: 0 },
      RightHandMiddleMid: { x: 0, y: 0, z: 0 },
      RightHandMiddleHigh: { x: 0, y: 0, z: 0 },
      RightHandRingLow: { x: 0, y: 0, z: 0 },
      RightHandRingMid: { x: 0, y: 0, z: 0 },
      RightHandRingHigh: { x: 0, y: 0, z: 0 },
      RightHandPinkyLow: { x: 0, y: 0, z: 0 },
      RightHandPinkyMid: { x: 0, y: 0, z: 0 },
      RightHandPinkyHigh: { x: 0, y: 0, z: 0 },
      LeftShoulder: { x: 0, y: 0, z: 0 },
      LeftArm: { x: 0, y: 0, z: 0 },
      LeftForeArm: { x: 0, y: 0, z: 0 },
      LeftHand: { x: 0, y: 0, z: 0 },
      LeftHandThumbLow: { x: 0, y: 0, z: 0 },
      LeftHandThumbMid: { x: 0, y: 0, z: 0 },
      LeftHandThumbHigh: { x: 0, y: 0, z: 0 },
      LeftHandIndexLow: { x: 0, y: 0, z: 0 },
      LeftHandIndexMid: { x: 0, y: 0, z: 0 },
      LeftHandIndexHigh: { x: 0, y: 0, z: 0 },
      LeftHandMiddleLow: { x: 0, y: 0, z: 0 },
      LeftHandMiddleMid: { x: 0, y: 0, z: 0 },
      LeftHandMiddleHigh: { x: 0, y: 0, z: 0 },
      LeftHandRingLow: { x: 0, y: 0, z: 0 },
      LeftHandRingMid: { x: 0, y: 0, z: 0 },
      LeftHandRingHigh: { x: 0, y: 0, z: 0 },
      LeftHandPinkyLow: { x: 0, y: 0, z: 0 },
      LeftHandPinkyMid: { x: 0, y: 0, z: 0 },
      LeftHandPinkyHigh: { x: 0, y: 0, z: 0 },
      RightUpLeg: { x: 0, y: 0, z: 0 },
      RightLeg: { x: 0, y: 0, z: 0 },
      RightFoot: { x: 0, y: 0, z: 0 },
      RightToeBase: { x: 0, y: 0, z: 0 },
      LeftUpLeg: { x: 0, y: 0, z: 0 },
      LeftLeg: { x: 0, y: 0, z: 0 },
      LeftFoot: { x: 0, y: 0, z: 0 },
      LeftToeBase: { x: 0, y: 0, z: 0 },
      trans: trans, // define the trans state object
      customPoseData: null,
    };
    this.meshObjects = {}; //This is for removing armor
    this.exportPose = this.exportPose.bind(this);
    this.exportPoseEvent = new Event('exportPose');
    this.camera = null;
    this.scene = null;
    this.renderer = null;
    this.controls = null;
    this.loader = null;
    this.isDragging = false;
    this.isDown = false;
    this.transformControlActive = false;
    //this.addEventListeners();
    //FPS Limiter
    this.renderFunction = renderFunction;
    this.fps = fps;
    this.lastFrameTime = 0;
    this.frameDuration = 1000 / this.fps;
    this.frameId = null;




    this.selected = null;
    const hex = "#00cfef";
    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;

    this.color = { r: r, g: g, b: b };
    this.meshColor = "#00cfef";
    this.baseColor = "#4e4e4e";
    //this.meshColor = meshColor;
    

    // This group will contain all the meshes but not the floor, the lights etc...
    this.group = new THREE.Group();
    this.group.name = "citizenZero"

    // Fix the spelling mistake while modeling.
    this.group.getMyObjectByName = function (name) {
      return this.group.getObjectByName(name);
    }.bind(this);

    this.frameId = null;  // To store the requestAnimationFrame ID
    this.isVisible = true;  // Visibility state of the canvas

    //This keeps track of every mesh on the viewport DEFAULT MESHES ON LOAD
    this.loadedMeshes = {
      Head: {
        name: "viper_helm",
        rotation: { x: 0, y: 0, z: 0 }
      },
      PadR: {
        name: "Default_PadR",
        rotation: { x: 0, y: 0, z: 0 }
      },
      PadL: {
        name: "Default_PadL",
        rotation: { x: 0, y: 0, z: 0 }
      },
      Chest: {
        name: "Default_Chest",
        rotation: { x: 0, y: 0, z: 0 }
      },
      Body: {
        name: "eb_m",
        rotation: { x: 0, y: 0, z: 0 }
      },
      LegR: {
        name: "Viper_LegR",
        rotation: { x: 0, y: 0, z: 0 }
      },
      LegL: {
        name: "Viper_LegL",
        rotation: { x: 0, y: 0, z: 0 }
      },
      ArmR: {
        name: "Viper_ArmR",
        rotation: { x: 0, y: 0, z: 0 }
      },
      ArmL: {
        name: "Viper_ArmL",
        rotation: { x: 0, y: 0, z: 0 }
      },
      HandR: {
        name: undefined,
        rotation: { x: 0, y: -1.57, z: 0 }
      },
      HandL: {
        name: undefined,
        rotation: { x: 0, y: 1.57, z: 0 }
      },
      FootR: {
        name: "Viper_FootR",
        rotation: { x: 0, y: 0, z: 0 }
      },
      FootL: {
        name: "Viper_FootL",
        rotation: { x: 0, y: 0, z: 0 }
      },
      Waist: {
        name: undefined,
        rotation: { x: 0, y: 0, z: 0 }
      },
      Back: {
        name: undefined,
        rotation: { x: 0, y: 0, z: 0 }
      },
      Stand: {
        name: "circle2",
        rotation: { x: 0, y: 0, z: 0 }
      },
      Env: {
        name: "testspawn",
        rotation: { x: 0, y: 0, z: 0 }
      },
      Podium: {
        name: "default",
        rotation: { x: 0, y: 0, z: 0 }
      },
      Prop01: {
        name: undefined,
        rotation: { x: 0, y: 0, z: 0 }
      },
      Prop02: {
        name: undefined,
        rotation: { x: 0, y: 0, z: 0 }
      },
      Prop03: {
        name: undefined,
        rotation: { x: 0, y: 0, z: 0 }
      }
    };

    // List of information on the meshes (attach points, body groups, etc...)
    this.meshStaticInfo = {
      Chest: {
        bodyPart: "chest",
        parentAttachment: undefined,
        childAttachment: undefined
      },
      Body: {
        bodyPart: "body",
        parentAttachment: undefined,
        childAttachment: undefined
      },
      Head: {
        bodyPart: "head",
        parentAttachment: undefined,
        childAttachment: undefined
      },
      PadR: {
        bodyPart: "pad",
        parentAttachment: undefined,
        childAttachment: undefined
      },
      PadL: {
        bodyPart: "pad",
        parentAttachment: undefined,
        childAttachment: undefined
      },
      Waist: {
        bodyPart: "waist",
        parentAttachment: undefined,
        childAttachment: undefined
      },
      Back: {
        bodyPart: "back",
        parentAttachment: undefined,
        childAttachment: undefined
      },
      ArmR: {
        bodyPart: "arm",
        parentAttachment: undefined,
        childAttachment: undefined
      },
      ArmL: {
        bodyPart: "arm",
        parentAttachment: undefined,
        childAttachment: undefined
      },
      HandR: {
        bodyPart: "hand",
        parentAttachment: "RightHand",
        childAttachment: "RightGlove"
      },
      HandL: {
        bodyPart: "hand",
        parentAttachment: "LeftHand",
        childAttachment: "LeftGlove"
      },
      LegR: {
        bodyPart: "leg",
        parentAttachment: "RightUpLeg",
        childAttachment: "RightLeg"
      },
      LegL: {
        bodyPart: "leg",
        parentAttachment: "LeftUpLeg",
        childAttachment: "LeftLeg"
      },
      FootR: {
        bodyPart: "foot",
        parentAttachment: "RightFoot",
        childAttachment: undefined
      },
      FootL: {
        bodyPart: "foot",
        parentAttachment: "LeftFoot",
        childAttachment: undefined
      }
    };

    //CHANGED
    // List of parent/child relations
    this.childrenList = {
      Body: ["PadL", "PadR", "HandR", "HandL", "FootR", "FootL", "ArmR", "ArmL", "Head", "Chest", "LegL", "LegR", "Waist", "Back"]
    };

    this.link = document.createElement("a");
    this.link.style.display = "none";
    document.body.appendChild(this.link);

    // Assuming there's a single element with the class 'banner'
    var banner = document.querySelector('.banner'); // This selects the first .banner element

    document.body.onresize = function () {
      // Use the dimensions of the .banner div
      var width = banner.offsetWidth;
      var height = banner.offsetHeight;

      // Resize the renderer to fit the .banner div
      this.renderer.setSize(width, height);

      // Update the camera's aspect ratio to match the new dimensions
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
    }.bind(this);


    // Expose global flags
    window.loaded = false;
    window.partloaded = false;

    // Expose global functions
    window.changeStand = this.changeStand.bind(this);
    window.changePodium = this.changePodium.bind(this);
    window.changeEnv = this.changeEnv.bind(this);
    window.changeProp = this.changeProp.bind(this);
    window.loadDefaultMeshes = this.loadDefaultMeshes.bind(this);
    window.changeMesh = this.changeMesh.bind(this);
    window.selectedMesh = this.selectedMesh.bind(this);
    window.getRotation = this.getRotation.bind(this);
    window.changeRotation = this.changeRotation.bind(this);
    window.loadPose = this.loadPose.bind(this);
    window.exportToSTL = this.exportToSTL.bind(this);
    window.saveScreenshot = this.saveScreenshot.bind(this);
    window.addEventListener('exportPose', this.exportPose);
    window.changePosition = this.changePosition.bind(this);
    window.changeSkybox = this.changeSkybox.bind(this);
    window.loadSkybox = this.loadSkybox.bind(this);
    window.removeMeshes = this.removeMeshes.bind(this);
    window.clearPose = this.clearPose.bind(this);
    window.removeAllArmor = this.removeAllArmor.bind(this);
    window.animate = this.animate.bind(this);
    window.setMeshColor = this.setMeshColor.bind(this);
  }

  // Init Function which will create all the
  // Three.js environment and load the default meshes
  init() {
    this.loader = new GLTFLoader();

    // Determine the path to the Draco decoder files based on the environment
    const dracoPath = process.env.NODE_ENV === 'production' ? '/DRACO/' : './DRACO/';

    // DRACO loader for compressed GLBs
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(dracoPath); // Use the dynamic path
    this.loader.setDRACOLoader(dracoLoader);

    this.scene = new THREE.Scene();
    this.scene.name = "scene"
    this.scene.position.set(0, 0, 0);
    this.scene.background = new THREE.Color(0x2d3150);
    this.scene.fog = new THREE.Fog(0x2d3150, 1, 50);
    this.scene.add(this.group);
    this.scene.getMyObjectByName = function (name) {
      return this.scene.getObjectByName(name);
    }.bind(this);
    //this.buildDevHelper();

    this.buildRenderer();
    this.buildCamera();
    this.canvas = this.renderer.domElement;
    const container = document.getElementById('FactoryApp'); // Ensure this exists in your HTML
    container.appendChild(this.renderer.domElement);

    this.mouse = new THREE.Vector2();
    this.raycaster = new THREE.Raycaster();
    this.canvas.addEventListener('click', this.onClick.bind(this), false);
    this.canvas.addEventListener('mousemove', this.onMouseMove.bind(this), false);

    this.transformControls = null;
    this.createTransformControls();
    this.addEventListeners();
    this.buildControls();
    this.buildLights();
    this.buildFloor();

    this.setupVisibilityHandling();  //Start observer
    this.animate(); // Start the animation initially
  }

  addEventListeners() {
    let resizeTimeout;
    window.addEventListener("mousedown", this.onMouseDown.bind(this));
    window.addEventListener("mousemove", this.onMouseMove.bind(this));
    window.addEventListener("mouseup", this.onMouseUp.bind(this));
    window.addEventListener('resize', () => {
      this.handleResizeImmediate();
    });
    window.addEventListener('click', () => {
      this.handleResizeImmediate();
    });
    window.addEventListener('blur', () => {
      this.handleResizeImmediate();
    });

    window.addEventListener('factoryResize', () => {
      this.handleResizeImmediate();
      setTimeout(() => {
        this.handleResizeImmediate();
      }, 900);
    });
  }

  handleResizeImmediate = () => {
    clearTimeout(this.resizeTimeout);

    const banner = document.querySelector('.banner');
    if (!banner) return;

    const width = banner.clientWidth;
    const height = banner.clientHeight;

    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  };


  // Add this method to handle visibility changes
  setupVisibilityHandling() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        this.isVisible = entry.isIntersecting;
        if (this.isVisible) {
          this.resumeAnimation();
        } else {
          this.pauseAnimation();
        }
      });
    }, { threshold: 0.1 });

    const sceneElement = document.getElementById('FactoryApp'); // Adjust selector as needed
    observer.observe(sceneElement);
  }




  buildDevHelper() {
    // build Axes
    let axes = new THREE.AxesHelper(2);
    axes.name = "axes";
    this.scene.add(axes);
  
    // build Grid
    let size = 50;
    let divisions = 60;
    let colorCenterLine = 0x306d7d;
    let colorGrid = 0x61dafb;
    let grid = new THREE.GridHelper(size, divisions, colorCenterLine, colorGrid);
    grid.name = "grid";
    this.scene.add(grid);
  
    // Add SkeletonHelper for the mesh body
    let skinnedMesh = this.scene.getObjectByName("mesh-body"); // Make sure the mesh is correctly named and added to the scene
  
    if (skinnedMesh && skinnedMesh.isSkinnedMesh) {
      let boneHelper = new THREE.SkeletonHelper(skinnedMesh);
      boneHelper.name = "boneHelper";
      this.scene.add(boneHelper);
    } else {
      console.warn("Mesh body or SkinnedMesh not found");
    }
  
    // expose scene to DOM
    window.scene = this.scene;
    window.THREE = THREE;
  }
  

  buildCameraOrtho() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const aspectRatio = width / height;
    const frustumSize = 10;

    this.camera = new THREE.OrthographicCamera(
      frustumSize * aspectRatio / -2, frustumSize * aspectRatio / 2,
      frustumSize / 2, frustumSize / -2,
      0.1, 1000
    );
    this.camera.name = "camera";
    this.camera.position.set(0, 1, 3);

    window.addEventListener('resize', () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      const newAspectRatio = newWidth / newHeight;
      this.camera.left = frustumSize * newAspectRatio / -2;
      this.camera.right = frustumSize * newAspectRatio / 2;
      this.camera.top = frustumSize / 2;
      this.camera.bottom = frustumSize / -2;
      this.camera.updateProjectionMatrix();
    });
  }

  buildCamera() {
    // Select the .banner div to get its dimensions
    const banner = document.querySelector('.banner');
    const width = banner.offsetWidth;
    const height = banner.offsetHeight;

    // Create a new PerspectiveCamera using the .banner div's aspect ratio
    this.camera = new THREE.PerspectiveCamera(
      25, // Field of view
      width / height, // Aspect ratio based on .banner div
      0.1, // Near clipping plane
      500 // Far clipping plane
    );
    this.camera.name = "camera";

    // Set the camera position
    this.camera.position.set(0, 0.5, 8);

    // Handle window resize

  }

  onWindowResize() {
    // Update the camera aspect ratio and renderer size
    const banner = document.querySelector('.banner');
    const width = banner.offsetWidth;
    const height = banner.offsetHeight;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    // Assuming this.renderer is your THREE.WebGLRenderer instance
    this.renderer.setSize(width, height);

    // You might also want to handle any other adjustments here
  }

  buildRenderer() {
    // Create a renderer with antialias
    this.renderer = new THREE.WebGLRenderer({
      logarithmicDepthBuffer: true,
      antialias: true,
      powerPreference: "default"
    });
    this.renderer.shadowMap.enabled = true;
    this.renderer.sortObjects = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default shadow map
  
    // Select the .banner div and use its dimensions for the renderer
    const banner = document.querySelector('.banner');
    const width = banner.clientWidth;
    const height = banner.clientHeight;
  
    // Configure renderer size to match the .banner div
    this.renderer.setSize(width, height);
  
    // Append the renderer to the .banner div instead of the body
    banner.appendChild(this.renderer.domElement);
  
    // Set up environment cube map
    //let path = "../img/textures/defaultjpg/";
    let path = "https://citizenzero.s3.us-west-1.amazonaws.com/skybox/Inferno Beach/";
    
    let extension = ".jpg";
    let urls = [
      path + "px" + extension, path + "nx" + extension,
      path + "py" + extension, path + "ny" + extension,
      path + "pz" + extension, path + "nz" + extension
    ];
  
    const reflectionCube = new THREE.CubeTextureLoader().load(urls, () => {
      // Set the environment map and background to the loaded texture
      this.scene.environment = reflectionCube;
      this.scene.background = reflectionCube;
    }, undefined, function (error) {
      console.error("Failed to load environment map", error);
    });
  
    reflectionCube.format = THREE.RGBAFormat;
    reflectionCube.mapping = THREE.CubeRefractionMapping;
    reflectionCube.TextureEncoding = THREE.SRGBColorSpace;
  
    // Tone mapping and exposure
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = Math.pow(0.9, 5.0);
    this.renderer.TextureEncoding = THREE.SRGBColorSpace;
  }
  

  buildRenderer3() {
    // Create a renderer with antialias
    this.renderer = new THREE.WebGLRenderer({
      logarithmicDepthBuffer: true,
      antialias: true,
      powerPreference: "default"
    });
    this.renderer.shadowMap.enabled = true;
    this.renderer.sortObjects = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default shadow map

    // Select the .banner div and use its dimensions for the renderer
    const banner = document.querySelector('.banner');
    const width = banner.clientWidth;
    const height = banner.clientHeight;

    // Configure renderer size to match the .banner div
    this.renderer.setSize(width, height);

    // Append the renderer to the .banner div instead of the body
    banner.appendChild(this.renderer.domElement);

    // Set up environment cube map
    let path = "../img/textures/defaultjpg/";
    let extension = ".jpg";
    let urls = [
      path + "px" + extension, path + "nx" + extension,
      path + "py" + extension, path + "ny" + extension,
      path + "pz" + extension, path + "nz" + extension
    ];

    let reflectionCube = new THREE.CubeTextureLoader().load(urls);
    reflectionCube.format = THREE.RGBAFormat;
    reflectionCube.mapping = THREE.CubeRefractionMapping;
    reflectionCube.TextureEncoding = THREE.SRGBColorSpace;

    // Set the environment for the scene
    this.scene.environment = reflectionCube;
    this.scene.background = reflectionCube;

    // Tone mapping and exposure
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = Math.pow(0.9, 5.0);
    this.renderer.TextureEncoding = THREE.SRGBColorSpace;
  }


  buildRendererold() {
    // Create a renderer with antialias
    this.renderer = new THREE.WebGLRenderer({
      logarithmicDepthBuffer: true,
      antialias: true,
      powerPreference: "default"
    });
    this.renderer.shadowMap.enabled = true;
    this.renderer.sortObjects = true;
    // default THREE.PCFShadowMap
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    // Configure renderer size to fill up the whole window
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(this.renderer.domElement);

    // px = left
    // nx = right
    // py = top
    // ny = bottom
    // pz = front
    // nz = back
    let path = "../img/textures/defaultjpg/";
    let extension = ".jpg";
    let urls = [
      path + "px" + extension,
      path + "nx" + extension,
      path + "py" + extension,
      path + "ny" + extension,
      path + "pz" + extension,
      path + "nz" + extension
    ];

    let reflectionCube = new THREE.CubeTextureLoader().load(urls);
    reflectionCube.format = THREE.RGBAFormat;
    reflectionCube.mapping = THREE.CubeRefractionMapping;
    reflectionCube.TextureEncoding = THREE.SRGBColorSpace; // Add this line

    // Set the environment for the scene
    this.renderer.TextureEncoding = THREE.SRGBColorSpace;
    this.scene.environment = reflectionCube;
    this.scene.background = reflectionCube;
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = Math.pow(0.9, 5.0);

  }


  buildControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.target.set(0, 1, 0);
    this.controls.update();
    this.controls.enabled = true;
    //Controlling max and min for ease of use
    this.controls.minDistance = 0;
    this.controls.maxDistance = 5;
    this.controls.minPolarAngle = 0;
    this.controls.maxPolarAngle = (Math.PI / 1.5 - 0.2);
    this.controls.enablePan = true;
    this.controls.panSpeed = 0.5;
    this.controls.screenSpacePanning = true;
    this.controls.minPan = (-2, -2, -2);
    this.controls.maxPan = (2, 2, 2);
    this.controls.enableDamping = true;
    this.controls.autoRotate = false;
    this.controls.mouseButtons = {
      LEFT: THREE.MOUSE.null,
      MIDDLE: THREE.MOUSE.PAN,
      RIGHT: THREE.MOUSE.ROTATE
    };

  }

  buildLights() {
    // Create an AmbientLight for a soft, uniform illumination
    let ambientLight = new THREE.AmbientLight(0xffffff, 0.0);
    ambientLight.name = "ambientLight";
    this.scene.add(ambientLight);

    // Create a directional light to simulate a primary light source
    let directionalLight = new THREE.DirectionalLight(0xffffff, 3);
    directionalLight.name = "directionalLight";
    directionalLight.position.set(0, 0, 5);
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.width = 4096;
    directionalLight.shadow.mapSize.height = 4096;
    directionalLight.shadow.camera.near = 0.5;
    directionalLight.shadow.camera.far = 50;
    directionalLight.shadow.bias = 0;
    this.scene.add(directionalLight);

    // Create a fill light to soften the shadows
    let fillLight = new THREE.PointLight(0xffffff, 0, 50);
    fillLight.name = "fillLight";
    fillLight.position.set(-10, 10, -10);
    this.scene.add(fillLight);

    // Create a back light to bring out details from the back and sides
    let backLight = new THREE.PointLight(0xffffff, 0, 50);
    backLight.name = "backLight";
    backLight.position.set(10, 10, -10);
    this.scene.add(backLight);

    //THI LINES BELOW ADD "REALISTIC" LIGHTING
    // Set the tone mapping of the renderer
    //this.renderer.toneMapping = THREE.ACESFilmicToneMapping;

    // Enable physically based rendering
    this.renderer.physicallyCorrectLights = true;
    //this.renderer.TextureEncoding = THREE.SRGBColorSpace;
  }

  buildFloor() {
    //Create a plane that receives shadows (but does not cast them)
    let planeGeometry = new THREE.PlaneGeometry(2000, 2000);
    planeGeometry.rotateX(- Math.PI / 2);

    let planeMaterial = new THREE.ShadowMaterial();
    planeMaterial.opacity = 0.2;

    let plane = new THREE.Mesh(planeGeometry, planeMaterial);
    plane.name = "plane";
    plane.rotation.x = -Math.PI / 2;
    plane.position.y = 0;
    plane.receiveShadow = false;
    this.scene.add(plane);
  }

  renderScene() {
    //this.camera.lookAt(new THREE.Vector3(0, 1, 0));
    this.renderer.render(this.scene, this.camera);
  }

  start() {
    this.frameId = requestAnimationFrame(this.animate.bind(this));
  }

  animate(currentTime) {
    // Request the next frame if visible
    if (this.isVisible) {
      //Play scene
        this.frameId = requestAnimationFrame(this.animate.bind(this));
      //Frame limiter
      const timeSinceLastFrame = currentTime - this.lastFrameTime;
      if (timeSinceLastFrame >= this.frameDuration) {
        this.renderScene();
        this.lastFrameTime = currentTime - (timeSinceLastFrame % this.frameDuration);
      }
      if (this.controls) {
        this.controls.update();
      }

    } else {
      // Ensure the animation loop is stopped when not visible
      this.pauseAnimation();
    }
  }

  stop() {
    if (this.frameId) {
      cancelAnimationFrame(this.frameId);
      this.frameId = null;
    }
  }

  pauseAnimation() {
    if (this.frameId) {
      cancelAnimationFrame(this.frameId);
      this.frameId = null; // Ensure no duplicate frame requests
    }
  }

  resumeAnimation() {
    if (!this.frameId) {
      setTimeout(() => {
        this.animate();
      }, 250);
    }
  }

  clearPosition(item) {
    // This function is used to clear the position of an imported glTF file
    item.position.x = 0;
    item.position.y = 0;
    item.position.z = 0;
  }

  rotateElement(item, clearRotation, rotation) {
    if (clearRotation === true) {
      item.rotation.x = 0;
      item.rotation.y = 0;
      item.rotation.z = 0;
    } else {
      item.rotation.x = rotation.x;
      item.rotation.y = rotation.y;
      item.rotation.z = rotation.z;
    }
  }

  updatePoseToggle(poseToggle) {
    // Update the state with the new poseToggle value
    this.state.poseToggle = poseToggle;

    // Traverse the scene graph and update the visibility of each sprite
    this.scene.traverse(function (child) {
      if (child instanceof THREE.Sprite) {
        child.visible = poseToggle;
      } else if (child.hasOwnProperty("transformControl")) {
        if (!poseToggle) {
          // Detach the transform control from the object
          child.transformControl.detach();
        }
      }
    });

    // If the poseToggle is false, hide the transform controls
    if (!poseToggle) {
      this.transformControls.visible = false;
      this.transformControls.detach();
    }
  }




  onTransformControlsChangePos(event) {
    const object = this.transformControls.object;
    if (object) {
      // Apply position change to child bones recursively
      const objectName = object.name;
      const position = object.position;
      this.changePosition(objectName, position.x, position.y, position.z);

      // Update the state
      if (this.state[objectName]) {
        this.state[objectName].x = position.x;
        this.state[objectName].y = position.y;
        this.state[objectName].z = position.z;
      }

      // Exclude 'trans' and 'customPoseData' properties from the state
      const { trans, customPoseData, ...remainingState } = this.state;

      // Check if the transformControls has an attached object
      if (this.transformControls.object) {
        const { trans, customPoseData, ...remainingState } = this.state;

        // Create a new object with the updated values from the state, excluding the 'trans' and 'customPoseData' properties
        const newCustomPoseData = {
          ...remainingState,
          [this.transformControls.object.name]: {
            x: objectName === "Root_Main" ? this.transformControls.object.position.x : this.transformControls.object.rotation.x,
            y: objectName === "Root_Main" ? this.transformControls.object.position.y : this.transformControls.object.rotation.y,
            z: objectName === "Root_Main" ? this.transformControls.object.position.z : this.transformControls.object.rotation.z
          }
        };

        // Set the new customPoseData as the pose in the state
        this.state.customPoseData = newCustomPoseData;
      }
    }
  }

  onTransformControlsChange2(event) {
    const bone = this.transformControls.object;
    if (bone) {
      // Apply rotation to child bones recursively
      const boneName = bone.name;
      const rotation = bone.rotation;
      this.changeRotation(boneName, rotation.x, 'x');
      this.changeRotation(boneName, rotation.y, 'y');
      this.changeRotation(boneName, rotation.z, 'z');

      // Update the state
      if (this.state[boneName]) {
        this.state[boneName].x = rotation.x;
        this.state[boneName].y = rotation.y;
        this.state[boneName].z = rotation.z;
      }
    }
  }
  onTransformControlsChange(event) {
    const bone = this.transformControls.object;

    this.transformControlInteraction = true;

    if (bone) {
      // Apply rotation to child bones recursively
      const boneName = bone.name;
      const rotation = bone.rotation;
      this.changeRotation(boneName, rotation.x, 'x');
      this.changeRotation(boneName, rotation.y, 'y');
      this.changeRotation(boneName, rotation.z, 'z');

      // Update the state
      if (this.state[boneName]) {
        this.state[boneName].x = rotation.x;
        this.state[boneName].y = rotation.y;
        this.state[boneName].z = rotation.z;
      }

      // Exclude 'trans' and 'customPoseData' properties from the state
      const { trans, customPoseData, ...remainingState } = this.state;

      // Check if the transformControls has an attached object
      if (this.transformControls.object) {
        const { trans, customPoseData, ...remainingState } = this.state;

        // Create a new object with the updated values from the state, excluding the 'trans' and 'customPoseData' properties
        const newCustomPoseData = {
          ...remainingState,
          [this.transformControls.object.name]: {
            x: boneName === "Root_Main" ? this.transformControls.object.position.x : this.transformControls.object.rotation.x,
            y: boneName === "Root_Main" ? this.transformControls.object.position.y : this.transformControls.object.rotation.y,
            z: boneName === "Root_Main" ? this.transformControls.object.position.z : this.transformControls.object.rotation.z
          }
        };

        // Set the new customPoseData as the pose in the state
        this.state.customPoseData = newCustomPoseData;
      }
    }
  }

  onClick(event) {
    // Check if the transformControls is currently dragging
    if (this.transformControls.dragging) return;

    // Return early if poseToggle is false
    if (!this.state.poseToggle) return;

    // Get the .banner div and its bounding rectangle
    const banner = document.querySelector('.banner');
    const rect = banner.getBoundingClientRect();

    // Normalize mouse position based on the .banner div
    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    // Update the picking ray with the camera and normalized mouse position
    this.raycaster.setFromCamera(this.mouse, this.camera);

    // Calculate objects intersecting the picking ray
    const intersects = this.raycaster.intersectObjects(this.group.children, true);

    if (intersects.length > 0) {
      // The first intersected object is the closest one
      const closestObject = intersects[0].object;

      // Check if the clicked object is a sprite
      if (closestObject instanceof THREE.Sprite) {
        // Get the bone which is the parent of the sprite
        const bone = closestObject.parent;

        // Attach the transform controls to the bone
        this.transformControls.attach(bone);

        // Set the transform controls space to local (to match the bone's orientation)
        this.transformControls.setSpace('local');

        // Set the mode of the transform controls based on the bone name
        if (bone.name === 'Root_Main' || bone.name.includes('prop')) {
          this.transformControls.setMode('translate');
          this.transformControls.addEventListener('change', (event) => this.onTransformControlsChangePos(event));
        } else {
          this.transformControls.setMode('rotate');
          this.transformControls.addEventListener('change', (event) => this.onTransformControlsChange(event));
        }

        // Change the size of the transform controls (e.g., 0.5 for half the size)
        this.transformControls.setSize(0.5);

        // Show the transform controls
        this.transformControls.visible = true;
      }
    }
  }



  onClickold(event) {
    // Check if the transformControls is currently dragging, and if it is, return without performing any actions
    if (this.transformControls.dragging) {
      return;
    }
    if (!this.state.poseToggle) {
      // If poseToggle is false, return without performing any actions
      return;
    }

    // Normalize mouse position
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    // Update the picking ray with the camera and mouse position
    this.raycaster.setFromCamera(this.mouse, this.camera);

    // Calculate objects intersecting the picking ray
    const intersects = this.raycaster.intersectObjects(this.group.children, true);

    if (intersects.length > 0) {
      // The first intersected object is the closest one
      const closestObject = intersects[0].object;

      // Check if the clicked object is a sprite
      if (closestObject instanceof THREE.Sprite) {
        // Get the bone which is the parent of the sprite
        const bone = closestObject.parent;

        // Attach the transform controls to the bone
        this.transformControls.attach(bone);

        // Set the transform controls space to local (to match the bone's orientation)
        this.transformControls.setSpace('local');

        // Set the mode of the transform controls based on the bone name
        if (bone.name === 'Root_Main' || bone.name === 'prop01_pos' || bone.name === 'prop02_pos' || bone.name === 'prop03_pos') {
          this.transformControls.setMode('translate');
          this.transformControls.addEventListener('change', (event) => this.onTransformControlsChangePos(event));
        } else {
          this.transformControls.setMode('rotate');
          this.transformControls.addEventListener('change', (event) => this.onTransformControlsChange(event));
        }

        // Change the size of the transform controls (e.g., 0.5 for half the size)
        this.transformControls.setSize(0.5);

        // Show the transform controls
        this.transformControls.visible = true;
      }
    }
  }

  onMouseMove = (event) => {
    if (!this.state.poseToggle) {
      return; // Exit early if pose toggling is disabled
    }

    // Calculate the normalized device coordinates (NDC) from the event
    const rect = this.renderer.domElement.getBoundingClientRect();
    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    // Update the picking ray with the camera and mouse position
    this.raycaster.setFromCamera(this.mouse, this.camera);

    // Traverse the scene to interact with sprites
    const intersects = [];
    this.scene.traverse((child) => {
      if (child instanceof THREE.Sprite && child.visible) { // Ensure child is a sprite and is visible
        child.raycast(this.raycaster, intersects);
        // Reset to original color as default
        child.material.color.set(child.originalColor);
        if (child.element) {
          child.element.classList.remove("sprite-button-hover");
        }
      }
    });

    const highlightcolor = getComputedStyle(document.documentElement)
      .getPropertyValue('--secondary').trim() || '#fff'; // Default color if CSS var is not found

    // Highlight and log hover events for intersected objects
    intersects.forEach((intersect) => {
      if (intersect.object instanceof THREE.Sprite) {
        intersect.object.material.color.set(highlightcolor); // Set to hover color
        if (intersect.object.element) {
          intersect.object.element.classList.add("sprite-button-hover");

        }
      }
    });

    // Handle mouse dragging logic
    if (this.isDown) {
      const currentMousePosition = new THREE.Vector2(event.clientX, event.clientY);
      if (this.mouseDownPosition.distanceTo(currentMousePosition) > 5) {
        this.isDragging = true;
      }
    }
  };



  onMouseDown = (e) => {
    this.isDragging = false;
    this.isDown = true;
    this.mouseDownPosition = new THREE.Vector2(e.clientX, e.clientY);
    //console.log("Mouse down");
  };

  onMouseMove2 = (e) => {
    if (this.isDown) {
      const currentMousePosition = new THREE.Vector2(e.clientX, e.clientY);
      if (this.mouseDownPosition.distanceTo(currentMousePosition) > 5) {
        this.isDragging = true;
        //console.log("Mouse move");
      }
    }
  };

  onMouseUp = (e) => {
    if (this.isDragging) {
      //console.log("Mouse drag - click disabled");
      e.stopPropagation(); // Stop event propagation
    } else if (!this.transformControlInteraction && !this.transformControls.object) {
      // Normalize mouse position
      this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
      // Update the picking ray with the camera and mouse position
      this.raycaster.setFromCamera(this.mouse, this.camera);

      // Calculate objects intersecting the picking ray
      const intersects = this.raycaster.intersectObjects(this.group.children, true);

      if (intersects.length === 0 || !(intersects[0].object instanceof THREE.Sprite)) {
        // Handle click event here only if there was no transform control interaction and no object is attached to transformControls
        //console.log("Mouse click");
      }
    }

    this.isDown = false;
    this.isDragging = false;
    // Reset the transform control interaction flag
    this.transformControlInteraction = false;
  };


  createTransformControls() {
    // Create a new instance of TransformControls
    this.transformControls = new TransformControls(this.camera, this.renderer.domElement);
    // Add the transform controls to the scene
    this.scene.add(this.transformControls);

    // Hide the transform controls initially
    this.transformControls.visible = false;
  }


  removeMeshes(meshType) {
    let meshToRemove = this.scene.getObjectByName(meshType);
    if (meshToRemove) {
      // Traverse the mesh and its children and dispose of their geometry and material
      meshToRemove.traverse(function (object) {
        if (object.geometry) object.geometry.dispose();
        if (object.material) object.material.dispose();
      });

      // If the mesh has a parent, remove the mesh from the parent
      if (meshToRemove.parent) {
        meshToRemove.parent.remove(meshToRemove);
      } else {
        // Otherwise, remove the mesh from the scene
        this.scene.remove(meshToRemove);
      }

      // Remove the mesh from the loadedMeshes variable
      //if (this.loadedMeshes[meshType]) {
      //delete this.loadedMeshes[meshType];
      //this.loadedMeshes[meshType] = undefined;
      //this.scene.updateMatrixWorld();
      //}
    } else {
      console.warn(`Mesh '${meshType}' not found in the scene`);
    }
  }


  clearPose(bones) {
    this.state.customPoseData = null;
    this.state.PoseData = null;
    this.scene.updateMatrixWorld();
  };


  setMeshNameToUndefined(name) {
    // Here you should map from the name of the mesh in the scene to the corresponding property in loadedMeshes.
    // This is just an example, you need to implement this mapping based on how your scene and loadedMeshes are set up.
    const meshNameToPropertyName = {
      "mesh-head": "Head",
      "mesh-padr": "PadR",
      "mesh-padl": "PadL",
      "mesh-chest": "Chest",
      "mesh-legr": "LegR",
      "mesh-legl": "LegL",
      "mesh-armr": "ArmR",
      "mesh-arml": "ArmL",
      "mesh-handr": "HandR",
      "mesh-handl": "HandL",
      "mesh-footr": "FootR",
      "mesh-footl": "FootL"
    };
    const propertyName = meshNameToPropertyName[name];
    if (propertyName) {
      this.loadedMeshes[propertyName].name = undefined;
    } else {
      console.warn('Could not find property for mesh name', name);
    }
  }


  setMeshColor(color) {
    const meshBodyColor = '#4e4e4e'; // Fixed color for body meshes
    const secondaryColor = '#757575'; // Fixed color for secondary material
    const exceptionMeshes = ['mesh-body', 'mesh-podium', 'mesh-stand'];
  
    // Convert the input color to a THREE.Color
    const baseColor = new THREE.Color(color);
  
    // Calculate the complementary color for glass material
    const complementaryColor = new THREE.Color(1 - baseColor.r, 1 - baseColor.g, 1 - baseColor.b);
  
    // Access the group of meshes
    const root = this.group;
  
    // Traverse the group to set colors based on material names
    root.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        // Handle materials based on their assigned names
        if (child.material.name === "glassMaterial") {
          // Set the complementary color for the glass material
          child.material.color.set(complementaryColor);
          child.material.needsUpdate = true;
        } else if (child.material.name === "secondaryMaterial") {
          // Set the fixed color for the secondary material
          child.material.color.set(secondaryColor);
          child.material.needsUpdate = true;
        } else if (child.material.name === "defaultMaterial" || child.material.name === "Primary") {
          // Set the provided base color for the primary material
          child.material.color.set(baseColor);
          child.material.needsUpdate = true;
        } else if (child.material.name === "wrongMaterial") {
          // Set the existing mesh color (no change to color property)
          child.material.color.set(child.material.color.clone());
          child.material.needsUpdate = true;
        } else if (exceptionMeshes.includes(child.name)) {
          // Set the fixed mesh body color
          child.material.color.set(meshBodyColor);
          child.material.needsUpdate = true;
        }
      }
    });
  
    // Update the state with the new color for tracking purposes
    this.meshColor = color;
  }
  
  
  







  placeMesh(
    meshName,
    bodyPartClass,
    meshType,
    parentAttachment,
    childAttachment,
    rotation,
    firstLoad,
    highLight,
    bones,
    poseData,
    metalness = 0,
    meshColor = this.meshColor,
    poseToggle
  ) {
    // bodyPartClass : {arm, head, hand, torso, leg, foot, pad}
    // meshType : {ArmR, ArmL, Head, HandR, HandL, LegR, LegL, FootR, FootL, Torso, PadR, PadL}

    // Skip loading if meshName is "none.glb" or "undefined.glb"
    if (meshName === "none" || meshName === "undefined") {
      console.log("Skipping load for: ", meshName);
      return;
    }
    console.log(bodyPartClass);
    this.loader.load(
      "https://citizenzero.s3.us-west-1.amazonaws.com/models/" + bodyPartClass + "/" + meshName + ".glb",
      glTF => {
        console.log(meshName);
        let root = glTF.scene.children[0];
        const self = this;
        root.traverse(function (child) {
          if (child instanceof THREE.Mesh) {
            // Assign names based on meshType
            if (meshType === "Body") {
              child.name = "mesh-body";
            } else if (meshType.toLowerCase() === "head") {
              child.name = "mesh-head";
            } else if (meshType === "Chest") {
              child.name = "mesh-chest";
            } else if (meshType === "ArmR") {
              child.name = "mesh-ArmR";
            } else if (meshType === "Glass") {
              // Assign a unique name to identify glass materials
              child.material.name = "glassMaterial";
            } else {
              child.name = `mesh-${meshType.toLowerCase()}`;
            }
      
              // Ensure all meshes cast and receive shadows
              child.castShadow = true;
              child.receiveShadow = true;
      
              // Disable frustum culling to ensure all objects are rendered
              child.traverse(function (obj) {
                  obj.frustumCulled = false;
              });

               // Convert meshColor to THREE.Color if it's not already
              const baseColor = new THREE.Color(meshColor);
              const secondaryColor = "#757575";

              // Calculate the opposite color by inverting RGB values
              const oppositeColor = new THREE.Color(1 - baseColor.r, 1 - baseColor.g, 1 - baseColor.b);
              if (child.material.name === "Glass") {
                // Create and define a new material for the "Glass"
                const glassMaterial = new THREE.MeshPhysicalMaterial({
                  color: oppositeColor,               // Set to opposite
                  metalness: 3.0,
                  roughness: 0.3,
                  transparent: false,
                  opacity: 1,
                  side: THREE.DoubleSide,
                  transmission: 1.0,
                  thickness: 0.5,
                  envMap: self.scene.environment,
                  envMapIntensity: 1.0,
                  clearcoat: 0.1,
                  clearcoatRoughness: 0.1,
                  reflectivity: 0.5,
                  depthWrite: false,
                  name: "glassMaterial",  // Add the name property here
                });
              
                // Apply the new glass material to the mesh
                child.material = glassMaterial;
              
              } else if (child.material.name === "Secondary") {
                // Create and define a new material for the "Secondary"
                const secondaryMaterial = new THREE.MeshPhysicalMaterial({
                  color: secondaryColor,
                  metalness: 0.0,                     // No metallic properties for plastic
                  roughness: 0.8,                     // High roughness for a matte finish
                  transmission: 0.0,                  // No transmission for non-glass materials
                  thickness: 0.1,                     // Thin thickness for minimal subsurface scattering
                  clearcoat: 0.1,                     // Very low clear coat to add a hint of gloss
                  clearcoatRoughness: 0.5,            // Some roughness on the clear coat
                  envMap: self.scene.environment,     // Environment map for reflections, if needed
                  envMapIntensity: 1.0,               // Intensity of the reflections
                  reflectivity: 0.1,                  // Low reflectivity to maintain the matte look
                  name: "secondaryMaterial",
                });
              
                // Apply the new secondary material to the mesh
                child.material = secondaryMaterial;
              
              } else if (child.material.name === "Primary") {
                // Define the material properties for other meshes
                const material = new THREE.MeshPhysicalMaterial({
                  color: meshColor,                   // Base color of the plastic
                  metalness: 0.0,                     // No metallic properties for plastic
                  roughness: 0.8,                     // High roughness for a matte finish
                  transmission: 0.0,                  // No transmission for non-glass materials
                  thickness: 0.1,                     // Thin thickness for minimal subsurface scattering
                  clearcoat: 0.1,                     // Very low clear coat to add a hint of gloss
                  clearcoatRoughness: 0.5,            // Some roughness on the clear coat
                  envMap: self.scene.environment,     // Environment map for reflections, if needed
                  envMapIntensity: 1.0,               // Intensity of the reflections
                  reflectivity: 0.1,                  // Low reflectivity to maintain the matte look
                  name: "defaultMaterial",            // Optional: assign a name if needed
                });
              
                // Apply the material to the mesh
                child.material = material;           
              

              } else {
                // Define the material properties for other meshes
                const wrongMaterial = new THREE.MeshPhysicalMaterial({
                  color: child.material.color.clone(),                   // Base color of the plastic
                  metalness: 0.0,                     // No metallic properties for plastic
                  roughness: 0.8,                     // High roughness for a matte finish
                  transmission: 0.0,                  // No transmission for non-glass materials
                  thickness: 0.1,                     // Thin thickness for minimal subsurface scattering
                  clearcoat: 0.1,                     // Very low clear coat to add a hint of gloss
                  clearcoatRoughness: 0.5,            // Some roughness on the clear coat
                  envMap: self.scene.environment,     // Environment map for reflections, if needed
                  envMapIntensity: 1.0,               // Intensity of the reflections
                  reflectivity: 0.1,                  // Low reflectivity to maintain the matte look
                  name: "wrongMaterial",            // Optional: assign a name if needed
                });
              
                // Apply the material to the mesh
                child.material = wrongMaterial;
              
                // Set a fixed color for the "mesh-body" mesh
                if (child.name === "mesh-body") {
                  child.material.color.set("#4e4e4e");
                }
              
              

              }
          }
            });
      

        root.traverse(function (child) {
          if (child.isBone && meshType === "Body") {
            // Use pink color for the circle if the bone is Root_Main, otherwise use blue
            const circleColor = child.name === "Root_Main" ? "#ff69b4" : getRandomColor();

            const circleTexture = new THREE.CanvasTexture(getCircleImage(32, circleColor, 1));
            const circleMaterial = new THREE.SpriteMaterial({
              map: circleTexture,
              depthWrite: false,
              depthTest: false
            });
            const circle = new THREE.Sprite(circleMaterial);
            circle.associatedBone = child;
            if (child.name === "Root_Main") {
              circle.scale.set(0.04, 0.04, 0.04); // Change the scale values for Root_Main as needed
            } else {
              circle.scale.set(0.01, 0.01, 0.01);
            }

            // Store the original color in a property of the circle
            circle.originalColor = circleColor;

            // Add the circle as a child of the bone
            child.add(circle);
            // Set the visibility of the circle based on the poseToggle value
            circle.visible = false;

            // Set the position of the circle in the bone's onBeforeRender event
            child.onBeforeRender = function () {
              circle.position.set(0, 0, 0); // Reset the position of the circle relative to the bone
              circle.localToWorld(new THREE.Vector3().setFromMatrixPosition(child.matrixWorld)); // Set the position of the circle in world space
            };

            // Enable raycasting for the sprite
            // This is necessary to detect clicks and mouseovers on the sprite
            // in a Three.js 3D space
            circle.raycast = function (raycaster, intersects) {
              // Create a new Three.js raycaster that starts at the mouse position and goes
              // towards the sprite's position in the 3D world
              const mouseRaycaster = new THREE.Raycaster(raycaster.ray.origin, raycaster.ray.direction.clone().normalize());

              // Calculate the distance between the mouse and the sprite
              const distance = mouseRaycaster.ray.distanceToPoint(circle.getWorldPosition(new THREE.Vector3()));

              // If the distance is less than the sprite's radius, there is a collision
              if (distance < circle.scale.x / 2) {
                // Push the sprite onto the `intersects` array so that the mouse event is detected
                intersects.push({
                  distance: distance,
                  object: circle
                });
              }
            };

            // Add event listeners to the sprite element
            circle.element = document.createElement('div');
            circle.element.className = 'sprite-button';
            circle.element.addEventListener('click', function () {
              // Perform desired action here
            });
            circle.element.addEventListener('mouseenter', function () {
              // Add a CSS class to the sprite's element when it is being hovered over
              circle.element.classList.add('sprite-button-hover');
            });
            circle.element.addEventListener('mouseleave', function () {
              // Remove the CSS class from the sprite's element when the mouse is no longer hovering over it
              circle.element.classList.remove('sprite-button-hover');
            });

            // Append sprite element to the DOM
            document.body.appendChild(circle.element);

          }
        });

        function getRandomColor() {
          const letters = '0123456789ABCDEF';
          let color = '#';
          for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          return color;
        }

        function getCircleImage(size, color, opacity) {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = size;
          canvas.height = size;
          context.beginPath();
          context.arc(size / 2, size / 2, size / 2 - 1, 0, 2 * Math.PI);
          context.fillStyle = color;
          context.globalAlpha = opacity;
          context.fill();
          return canvas;
        }

        // Fix naming errors in modeling (errors in glb).
        root.name = meshType;

        // group is one element with all the meshes and bones of the character
        this.group.add(root);
        this.scene.updateMatrixWorld(true);

        // Store the actual mesh object for later use
        this.meshObjects[meshType] = root;

        // Updates the loadedMeshes variable (used for replacing children)changeColor
        this.loadedMeshes[meshType].name = meshName;
        this.loadedMeshes[meshType].rotation = rotation;

        //if (meshType === "Head" && firstLoad) {
        //    this.changeColor("Head", this.color);
        // }

        //if (highLight) {
        //    this.changeColor(meshType, this.color);
        //}
        //console.log(this.state.customPoseData);
        if (this.state.customPoseData) {
          window.loadPose(this.state.customPoseData, bones);
          root.traverse(function (child) {
            if (child instanceof THREE.Bone) {
              if (this.state.customPoseData[child.name]) {
                // If the bone is "Root_Main", handle position, otherwise handle rotation
                if (child.name === "Root_Main") {
                  window.changePosition(child.name, this.state.customPoseData[child.name].x, this.state.customPoseData[child.name].y, this.state.customPoseData[child.name].z);
                } else {
                  window.changeRotation(child.name, this.state.customPoseData[child.name].x, "x");
                  window.changeRotation(child.name, this.state.customPoseData[child.name].y, "y");
                  window.changeRotation(child.name, this.state.customPoseData[child.name].z, "z");
                }
              }
            }
          });
        } else {
          if (poseData) {
            window.loadPose(poseData, bones);
            root.traverse(function (child) {
              if (child instanceof THREE.Bone) {
                if (poseData[child.name]) {
                  // If the bone is "Root_Main", handle position, otherwise handle rotation
                  if (child.name === "Root_Main") {
                    window.changePosition(child.name, poseData[child.name].x, poseData[child.name].y, poseData[child.name].z);
                  } else {
                    window.changeRotation(child.name, poseData[child.name].x, "x");
                    window.changeRotation(child.name, poseData[child.name].y, "y");
                    window.changeRotation(child.name, poseData[child.name].z, "z");
                  }
                }
              }
            });
          }
        }
        if (
          typeof parentAttachment !== "undefined" &&
          typeof childAttachment !== "undefined"
        ) {
          let targetBone = this.scene.getMyObjectByName("Body");
          let object = root;
          this.clearPosition(object);
          this.rotateElement(object, true);
          this.rotateElement(object, false, rotation);
          targetBone.add(object);
        }


        //Going to look for all children of current mesh
        let children = this.childrenList[meshType];
        if (children) {
          for (let i = 0; i < children.length; i++) {
            this.replaceMesh(children[i], firstLoad, bones, poseData);
          }
        }
        window.partloaded = true;
      },
      null,
      function (error) {
        console.log(error);
      }
    );
  }

  changeMesh(bodyPart, part, isLeft, bones, poseData) {
    window.partloaded = false;
    let meshType;
    let file;
    let rotation;

    switch (bodyPart) {
      case "pad":
        meshType = isLeft ? "PadL" : "PadR";
        if (part.file.includes(";")) {
          const splitFile = part.file.split(";");
          file = isLeft ? splitFile[0] : splitFile[1];
        } else {
          file = part.file;
        }
        break;
      case "body":
        file = part.file;
        meshType = "Body";
        break;
      case "chest":
        file = part.file;
        meshType = "Chest";
        break;
      case "head":
        file = part.file;
        meshType = "Head";
        break;
      case "waist":
        file = part.file;
        meshType = "Waist";
        break;
      case "back":
        file = part.file;
        meshType = "Back";
        break;
      case "hand":
        meshType = isLeft ? "HandL" : "HandR";
        if (part.file.includes(";")) {
          const splitFile = part.file.split(";");
          file = isLeft ? splitFile[0] : splitFile[1];
        } else {
          file = part.file;
        }
        break;
      case "arm":
        meshType = isLeft ? "ArmL" : "ArmR";
        if (part.file.includes(";")) {
          const splitFile = part.file.split(";");
          file = isLeft ? splitFile[0] : splitFile[1];
        } else {
          file = part.file;
        }
        break;
      case "foot":
        meshType = isLeft ? "FootL" : "FootR";
        if (part.file.includes(";")) {
          const splitFile = part.file.split(";");
          file = isLeft ? splitFile[0] : splitFile[1];
        } else {
          file = part.file;
        }
        break;
      case "leg":
        meshType = isLeft ? "LegL" : "LegR";
        if (part.file.includes(";")) {
          const splitFile = part.file.split(";");
          file = isLeft ? splitFile[0] : splitFile[1];
        } else {
          file = part.file;
        }
        break;
      default:
        meshType = undefined;
    }

    if (meshType) {
      let parentAttachment = this.meshStaticInfo[meshType].parentAttachment;
      let childAttachment = this.meshStaticInfo[meshType].childAttachment;
      let currentMesh = this.scene.getMyObjectByName(meshType);
      let bonesToDelete;

      if (meshType === "Body") {
        bonesToDelete = this.scene.getMyObjectByName("Body");
        if (bonesToDelete) {
          this.scene.remove(currentMesh);
          deleteBoneAndChildren(bonesToDelete);
        }
      } else {
        bonesToDelete = this.scene.getMyObjectByName(meshType);
        if (bonesToDelete) {
          this.scene.remove(currentMesh);
          deleteBoneAndChildren(bonesToDelete);
        }
      }

      function deleteBoneAndChildren(bone) {
        if (bone) {
          bone.parent.remove(bone);
          for (let i = bone.children.length - 1; i >= 0; i--) {
            let child = bone.children[i];
            deleteBoneAndChildren(child);
          }
        }
      }

      if (currentMesh && bonesToDelete) {
        bonesToDelete.remove(currentMesh);
        if (bonesToDelete.children) {
          for (let i = 0; i < bonesToDelete.children.length; i++) {
            if (bonesToDelete.children[i] instanceof THREE.Bone) {
              bonesToDelete.remove(bonesToDelete.children[i]);
            }
          }
        }
      }

      this.placeMesh(
        file,
        bodyPart,
        meshType,
        parentAttachment,
        childAttachment,
        rotation,
        false,
        true,
        bones,
        poseData
      );
    }
    
    return true;
  }




  replaceMesh(meshType, firstLoad, bones, poseData) {
    this.group.remove(this.group.getMyObjectByName(meshType));
    this.placeMesh(
      this.loadedMeshes[meshType].name,
      this.meshStaticInfo[meshType].bodyPart,
      meshType,
      this.meshStaticInfo[meshType].parentAttachment,
      this.meshStaticInfo[meshType].childAttachment,
      this.loadedMeshes[meshType].rotation,
      firstLoad,
      false,
      bones,
      poseData
    );
  }

  placeStand(metalness = 0, meshColor = this.baseColor) {
    // Check if the mesh-stand is already in the scene
    if (this.scene.getObjectByName("mesh-stand")) {
      console.log("Mesh stand already placed");
    } else {
      this.loader.load(
        "https://citizenzero.s3.us-west-1.amazonaws.com/models/stand/circle2.glb",
        (glTF) => {
          let root = glTF.scene.children[0];
          const self = this; // Reference to access scene environment correctly
          root.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              child.name = "mesh-stand";
              child.castShadow = true;
              child.receiveShadow = true;
  
              const material = new THREE.MeshStandardMaterial({
                color: meshColor,
                metalness: 0.0, // Adjust as needed
                roughness: 0.4, // Adjust as needed
                envMap: self.scene.environment, // Use the environment map
                envMapIntensity: 1.0, // Control the strength of reflections
                reflectivity: 1.0,
              });
  
              // If needed, convert color to linear space
              //material.color.convertSRGBToLinear();
              child.material = material;
            }
          });
  
          this.group.add(root);
          //this.scene.add(this.group); // Add group to the scene if not already done
        },
        undefined,
        function (error) {
          console.log("Error loading stand model:", error);
        }
      );
    }
  }
  

  changeStand(stand, metalness = 0, meshColor = this.baseColor,) {
    if (this.scene.getMyObjectByName("mesh-stand")) {
      this.group.remove(this.scene.getMyObjectByName("mesh-stand"));
      this.group.remove(this.scene.getMyObjectByName("mesh-stand"));
      this.loader.load(
        "https://citizenzero.s3.us-west-1.amazonaws.com/models/stand/" + stand + ".glb",
        glTF => {
          let root = glTF.scene.children[0];
          root.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              child.name = "mesh-stand";
              child.castShadow = true;
              child.receiveShadow = true;
  
              const material = new THREE.MeshStandardMaterial({
                color: meshColor,
                metalness: 0.0, // Adjust as needed
                roughness: 0.4, // Adjust as needed
                envMap: this.scene.environment, // Use the environment map
                envMapIntensity: 1.0, // Control the strength of reflections
                reflectivity: 1.0,
              });
  
              // If needed, convert color to linear space
              //material.color.convertSRGBToLinear();
              child.material = material;
            }
          });
          this.group.add(root);
        },
        null,
        function (error) {
          console.log(error);
        }
      );
    }
  };

  placePodium(metalness = 0, meshColor = this.baseColor) {
    if (this.scene.getObjectByName("mesh-podium")) {
      // Podium is already placed
    } else {
      this.loader.load(
        "https://citizenzero.s3.us-west-1.amazonaws.com/models/podium/default.glb",
        (glTF) => {
          let root = glTF.scene.children[0];
          root.traverse((child) => {
            if (child instanceof THREE.Mesh) {
              child.name = "mesh-podium";
              child.castShadow = true;
              child.receiveShadow = true;
              const material = new THREE.MeshStandardMaterial({
                color: meshColor,
                metalness: 0.0, // Adjust as needed
                roughness: 0.4, // Adjust as needed
                envMap: this.scene.environment, // Use the environment map
                envMapIntensity: 1.0, // Control the strength of reflections
                reflectivity: 1.0,
              });

              //material.color.convertSRGBToLinear();
              child.material = material;
            }
          });

          this.group.add(root);
          //this.scene.add(this.group);
        },
        undefined,
        function (error) {
          console.log(error);
        }
      );
    }
  };


  changePodium(podium, metalness = 0, meshColor = this.baseColor,) {
    if (this.scene.getMyObjectByName("mesh-podium")) {
      this.group.remove(this.scene.getMyObjectByName("mesh-podium"));
      this.group.remove(this.scene.getMyObjectByName("mesh-podium"));
      this.loader.load(
        "https://citizenzero.s3.us-west-1.amazonaws.com/models/podium/" + podium + ".glb",
        glTF => {
          let root = glTF.scene.children[0];
          root.traverse((child) => {
            if (child instanceof THREE.Mesh) {
              child.name = "mesh-podium";
              child.castShadow = true;
              child.receiveShadow = true;
              const material = new THREE.MeshStandardMaterial({
                color: meshColor,
                metalness: 0.0, // Adjust as needed
                roughness: 0.4, // Adjust as needed
                envMap: this.scene.environment, // Use the environment map
                envMapIntensity: 1.0, // Control the strength of reflections
                reflectivity: 1.0,
              });

              //material.color.convertSRGBToLinear();
              child.material = material;
            }
          });
          this.group.add(root);
        },
        null,
        function (error) {
          console.log(error);
        }
      );
    }
  };

  placeEnv() {
    if (this.scene.getMyObjectByName("meshEnv")) {
        // Do nothing if the environment is already placed
    } else {
        this.loader.load(
            "https://citizenzero.s3.us-west-1.amazonaws.com/models/env/newspawn.glb",
            glTF => {
                let root = glTF.scene;
                root.position.set(0, -0.125, 0);
                root.traverse((child) => {
                    if (child.isMesh) {
                        child.name = "meshEnv";
                        child.castShadow = true;
                        child.receiveShadow = true;

                        const material = child.material;

                        // Check if the material's name is 'Emissive'
                        if (material.name === 'Emmissive') {
                            material.emissive = new THREE.Color(0x00fcef); // Set emissive color
                            material.emissiveIntensity = 10; // Set emissive intensity
                        }
                    }
                });

                this.scene.add(root);
                window.loaded = true;
            },
            null,
            function (error) {
                console.log(error);
            }
        );
    }
}


  changeEnv(env) {
    this.group.remove(this.scene.getMyObjectByName("meshEnv"));
    this.group.remove(this.scene.getMyObjectByName("meshEnv"));
    this.loader.load(
      "https://citizenzero.s3.us-west-1.amazonaws.com/models/env/" + env + ".gltf",
      glTF => {
        let root = glTF.scene;
        root.position.set(0, -0.125, 0);
        root.traverse(function (child) {
          child.name = "meshEnv";
          child.castShadow = true;
          child.receiveShadow = true;
          
        });
        this.group.add(root);
      },
      null,
      function (error) {
        console.log(error);
      }
    );
  };


  placeProp01(prop01, meshType) {
    if (this.scene.getMyObjectByName("meshProp01")) {
    } else {
      this.loader.load(
        "https://citizenzero.s3.us-west-1.amazonaws.com/models/prop/none.glb",
        glTF => {
          let root = glTF.scene;
          root.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              child.name = "meshProp01";
              child.castShadow = true;
              child.receiveShadow = true;
              child.scale.set(0.55, 0.55, 0.55);
              child.position.set(-0.125, 0.06, 0);
              child.material.metalness = 0.5;
              child.traverse(function (obj) {
                if (obj instanceof THREE.Bone) {
                  obj.name = "prop01_" + obj.name;
                }
                obj.frustumCulled = false;
              });
            }
          });

          root.traverse(function (child) {
            if (child.isBone && meshType === "Prop01") {
              const circleColor = child.name === "prop01_pos" ? "#ffae00" : "#00ff08";

              const circleTexture = new THREE.CanvasTexture(getCircleImage(32, circleColor, 1));
              const circleMaterial = new THREE.SpriteMaterial({
                map: circleTexture,
                depthWrite: false,
                depthTest: false
              });
              const circle = new THREE.Sprite(circleMaterial);
              circle.associatedBone = child;
              circle.scale.set(0.5, 0.5, 0.5);

              circle.originalColor = circleColor;

              child.add(circle);
              circle.visible = false;

              child.onBeforeRender = function () {
                circle.position.set(0, 0, 0);
                circle.localToWorld(new THREE.Vector3().setFromMatrixPosition(child.matrixWorld));
              };

              circle.raycast = function (raycaster, intersects) {
                const mouseRaycaster = new THREE.Raycaster(raycaster.ray.origin, raycaster.ray.direction.clone().normalize());

                const distance = mouseRaycaster.ray.distanceToPoint(circle.getWorldPosition(new THREE.Vector3()));

                if (distance < (circle.scale.x / 2) * 0.0625) {
                  intersects.push({
                    distance: distance,
                    object: circle
                  });
                }
              };

              circle.element = document.createElement("div");
              circle.element.className = "sprite-button";
              circle.element.addEventListener("click", function () {
                // Perform desired action here
              });
              circle.element.addEventListener("mouseenter", function () {
                circle.element.classList.add("sprite-button-hover");
              });
              circle.element.addEventListener("mouseleave", function () {
                circle.element.classList.remove("sprite-button-hover");
              });

              document.body.appendChild(circle.element);
              console.log(getCircleImage(32, circleColor, 0.75));
            }
          });

          function getCircleImage(size, color, opacity) {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = size;
            canvas.height = size;
            context.beginPath();
            context.arc(size / 2, size / 2, size / 2 - 1, 0, 2 * Math.PI);
            context.fillStyle = color
            context.globalAlpha = opacity;
            context.fill();
            return canvas;
          }

          this.group.add(root);
        },
        null,
        function (error) {
          console.log(error);
        }
      );
    }
  };

  placeProp02(prop02, meshType) {
    if (this.scene.getMyObjectByName("meshProp02")) {
    } else {
      this.loader.load(
        "https://citizenzero.s3.us-west-1.amazonaws.com/models/prop/none.glb",
        glTF => {
          let root = glTF.scene;
          root.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              child.name = "meshProp02";
              child.castShadow = true;
              child.receiveShadow = true;
              child.scale.set(0.55, 0.55, 0.55);
              child.position.set(-0.125, 0.06, 0);
              child.material.metalness = 0.5;
              child.traverse(function (obj) {
                if (obj instanceof THREE.Bone) {
                  obj.name = "prop02_" + obj.name;
                }
                obj.frustumCulled = false;
              });
            }
          });

          root.traverse(function (child) {
            if (child.isBone && meshType === "Prop02") {
              const circleColor = child.name === "prop02_pos" ? "#ffae00" : "#00ff08";

              const circleTexture = new THREE.CanvasTexture(getCircleImage(32, circleColor, 1));
              const circleMaterial = new THREE.SpriteMaterial({
                map: circleTexture,
                depthWrite: false,
                depthTest: false
              });
              const circle = new THREE.Sprite(circleMaterial);
              circle.associatedBone = child;
              circle.scale.set(0.5, 0.5, 0.5);

              circle.originalColor = circleColor;

              child.add(circle);
              circle.visible = false;

              child.onBeforeRender = function () {
                circle.position.set(0, 0, 0);
                circle.localToWorld(new THREE.Vector3().setFromMatrixPosition(child.matrixWorld));
              };

              circle.raycast = function (raycaster, intersects) {
                const mouseRaycaster = new THREE.Raycaster(raycaster.ray.origin, raycaster.ray.direction.clone().normalize());

                const distance = mouseRaycaster.ray.distanceToPoint(circle.getWorldPosition(new THREE.Vector3()));

                if (distance < (circle.scale.x / 2) * 0.0625) {
                  intersects.push({
                    distance: distance,
                    object: circle
                  });
                }
              };

              circle.element = document.createElement("div");
              circle.element.className = "sprite-button";
              circle.element.addEventListener("click", function () {
                // Perform desired action here
              });
              circle.element.addEventListener("mouseenter", function () {
                circle.element.classList.add("sprite-button-hover");
              });
              circle.element.addEventListener("mouseleave", function () {
                circle.element.classList.remove("sprite-button-hover");
              });

              document.body.appendChild(circle.element);
              console.log(getCircleImage(32, circleColor, 0.75));
            }
          });

          function getCircleImage(size, color, opacity) {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = size;
            canvas.height = size;
            context.beginPath();
            context.arc(size / 2, size / 2, size / 2 - 1, 0, 2 * Math.PI);
            context.fillStyle = color
            context.globalAlpha = opacity;
            context.fill();
            return canvas;
          }

          this.group.add(root);
        },
        null,
        function (error) {
          console.log(error);
        }
      );
    }
  };

  placeProp03(prop03, meshType) {
    if (this.scene.getMyObjectByName("meshProp03")) {
    } else {
      this.loader.load(
        "https://citizenzero.s3.us-west-1.amazonaws.com/models/prop/none.glb",
        glTF => {
          let root = glTF.scene;
          root.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              child.name = "meshProp03";
              child.castShadow = true;
              child.receiveShadow = true;
              child.scale.set(0.55, 0.55, 0.55);
              child.position.set(-0.125, 0.06, 0);
              child.material.metalness = 0.5;
              child.traverse(function (obj) {
                if (obj instanceof THREE.Bone) {
                  obj.name = "prop03_" + obj.name;
                }
                obj.frustumCulled = false;
              });
            }
          });

          root.traverse(function (child) {
            if (child.isBone && meshType === "Prop03") {
              const circleColor = child.name === "prop03_pos" ? "#ffae00" : "#00ff08";

              const circleTexture = new THREE.CanvasTexture(getCircleImage(32, circleColor, 1));
              const circleMaterial = new THREE.SpriteMaterial({
                map: circleTexture,
                depthWrite: false,
                depthTest: false
              });
              const circle = new THREE.Sprite(circleMaterial);
              circle.associatedBone = child;
              circle.scale.set(0.5, 0.5, 0.5);

              circle.originalColor = circleColor;

              child.add(circle);
              circle.visible = false;

              child.onBeforeRender = function () {
                circle.position.set(0, 0, 0);
                circle.localToWorld(new THREE.Vector3().setFromMatrixPosition(child.matrixWorld));
              };

              circle.raycast = function (raycaster, intersects) {
                const mouseRaycaster = new THREE.Raycaster(raycaster.ray.origin, raycaster.ray.direction.clone().normalize());

                const distance = mouseRaycaster.ray.distanceToPoint(circle.getWorldPosition(new THREE.Vector3()));

                if (distance < (circle.scale.x / 2) * 0.0625) {
                  intersects.push({
                    distance: distance,
                    object: circle
                  });
                }
              };

              circle.element = document.createElement("div");
              circle.element.className = "sprite-button";
              circle.element.addEventListener("click", function () {
                // Perform desired action here
              });
              circle.element.addEventListener("mouseenter", function () {
                circle.element.classList.add("sprite-button-hover");
              });
              circle.element.addEventListener("mouseleave", function () {
                circle.element.classList.remove("sprite-button-hover");
              });

              document.body.appendChild(circle.element);
              console.log(getCircleImage(32, circleColor, 0.75));
            }
          });

          function getCircleImage(size, color, opacity) {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = size;
            canvas.height = size;
            context.beginPath();
            context.arc(size / 2, size / 2, size / 2 - 1, 0, 2 * Math.PI);
            context.fillStyle = color
            context.globalAlpha = opacity;
            context.fill();
            return canvas;
          }

          this.group.add(root);
        },
        null,
        function (error) {
          console.log(error);
        }
      );
    }
  };

  changeProp(prop, meshType) {
    console.log("changeProp!");
    console.log(prop);
    console.log("changeProp!");
    // Determine the mesh name to remove based on the meshType
    let meshToRemove;
    if (meshType === "Prop03") {
      meshToRemove = "meshProp03";
    } else if (meshType === "Prop02") {
      meshToRemove = "meshProp02";
    } else if (meshType === "Prop01") {
      meshToRemove = "meshProp01";
    }
    // Traverse the scene and remove the mesh with the matching name
    if (meshToRemove) {
      this.scene.traverse((object) => {
        if (object.name === meshToRemove) {
          object.parent.remove(object);
        }
      });
    }
    this.loader.load(
      "https://citizenzero.s3.us-west-1.amazonaws.com/models/prop/" + prop + ".glb",
      glTF => {
        let root = glTF.scene;
        root.traverse(function (child) {
          if (child instanceof THREE.Mesh) {
            if (meshType === "Prop03") {
              child.name = "meshProp03";
              child.position.set(0.125, 0.06, 0);
              child.traverse(function (obj) {
                if (obj instanceof THREE.Bone) {
                  obj.name = "prop03_" + obj.name;
                }
              });
            } else if (meshType === "Prop02") {
              child.name = "meshProp02";
              child.position.set(0, 0.06, -0.125);
              child.traverse(function (obj) {
                if (obj instanceof THREE.Bone) {
                  obj.name = "prop02_" + obj.name;
                }
              });
            } else if (meshType === "Prop01") {
              child.name = "meshProp01";
              child.position.set(-0.125, 0.06, 0);
              child.traverse(function (obj) {
                if (obj instanceof THREE.Bone) {
                  obj.name = "prop01_" + obj.name;
                }
              });
            }
            child.castShadow = true;
            child.receiveShadow = true;
            child.scale.set(1, 1, 1);
            child.material.metalness = 0.5;
            child.frustumCulled = false;
          }
        });

        root.traverse(function (child) {
          if (child.isBone) {
            const circleColor = child.name === "prop03_pos" || child.name === "prop02_pos" || child.name === "prop01_pos" ? "#ffae00" : "#00ff08";

            const circleTexture = new THREE.CanvasTexture(getCircleImage(32, circleColor, 1));
            const circleMaterial = new THREE.SpriteMaterial({
              map: circleTexture,
              depthWrite: false,
              depthTest: false
            });
            const circle = new THREE.Sprite(circleMaterial);
            circle.associatedBone = child;
            circle.scale.set(0.5, 0.5, 0.5);

            circle.originalColor = circleColor;

            child.add(circle);
            circle.visible = false;

            child.onBeforeRender = function () {
              circle.position.set(0, 0, 0);
              circle.localToWorld(new THREE.Vector3().setFromMatrixPosition(child.matrixWorld));
            };

            circle.raycast = function (raycaster, intersects) {
              const mouseRaycaster = new THREE.Raycaster(raycaster.ray.origin, raycaster.ray.direction.clone().normalize());

              const distance = mouseRaycaster.ray.distanceToPoint(circle.getWorldPosition(new THREE.Vector3()));

              if (distance < (circle.scale.x / 2) * 0.0625) {
                intersects.push({
                  distance: distance,
                  object: circle
                });
              }
            };

            circle.element = document.createElement("div");
            circle.element.className = "sprite-button";
            circle.element.addEventListener("click", function () {
              // Perform desired action here
            });
            circle.element.addEventListener("mouseenter", function () {
              circle.element.classList.add("sprite-button-hover");
            });
            circle.element.addEventListener("mouseleave", function () {
              circle.element.classList.remove("sprite-button-hover");
            });

            document.body.appendChild(circle.element);
            console.log(getCircleImage(32, circleColor, 0.75));
          }
        });

        function getCircleImage(size, color, opacity) {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = size;
          canvas.height = size;
          context.beginPath();
          context.arc(size / 2, size / 2, size / 2 - 1, 0, 2 * Math.PI);
          context.fillStyle = color
          context.globalAlpha = opacity;
          context.fill();
          return canvas;
        }

        this.group.add(root);
      },
      null,
      function (error) {
        console.log(error);
      }
    );
  };

  loadDefaultMeshes(bones, poseData) {
    this.placeMesh(
      this.loadedMeshes["Body"].name,
      this.meshStaticInfo["Body"].bodyPart,
      "Body",
      undefined,
      undefined,
      undefined,
      true,
      false,
      bones,
      poseData
    );
    this.placeEnv();
    this.placePodium();
    this.placeStand();
    this.placeProp01();
    this.placeProp02();
    this.placeProp03();
  };

  selectedMesh(meshType) {
    //let normal = {r: 1.0, g: 1.0, b: 1.0};
    //this.changeColor(this.selected, normal);
    //this.changeColor(meshType, this.color);
    //this.selected = meshType;
  };

  changeColor(item, chosenColor) {
    //let mesh = item === "pose" ? this.group : this.scene.getMyObjectByName(item);
    //mesh.traverse(function (child) {
    //    if (child instanceof THREE.Mesh) {
    //        if (child.material) {
    //            if (item === "pose" && child.name !== "env") {
    //                child.material.color.setRGB(1.0, 1.0, 1.0);
    //            } else {
    //                child.material.color.setRGB(chosenColor.r, chosenColor.g, chosenColor.b);
    //            }
    //        }
    //    }
    //});
  };

  getRotation(bone_name) {
    // Ignore the "Root_Main" bone
    if (bone_name === "Root_Main") {
      return null;
    }

    let bone = this.scene.getMyObjectByName(bone_name);
    if (bone instanceof THREE.Bone) {
      return { x: bone.rotation.x, y: bone.rotation.y, z: bone.rotation.z };
    }
  };

  changeRotation(bone_name, value, axis) {
    let bone = this.scene.getMyObjectByName(bone_name);
    if (bone instanceof THREE.Bone) {
      // Apply rotation to the current bone
      switch (axis) {
        case "x":
          bone.rotation.x = value;
          break;
        case "y":
          bone.rotation.y = value;
          break;
        case "z":
          bone.rotation.z = value;
          break;
        default:
      }

      // Apply rotation to child bones recursively
      const children = this.state.trans[bone_name];
      if (children) {
        for (let child in children) {
          this.changeRotation(child, value, axis);
        }
      }
    }
  }

  changePosition(boneName, x, y, z) {
    let bone = this.scene.getMyObjectByName(boneName);
    if (bone instanceof THREE.Bone) {
      // Update the position of the bone
      bone.position.set(x, y, z);

      // Apply the same position change to child bones recursively
      const children = this.state.trans[boneName];
      if (children) {
        for (let child in children) {
          this.changePosition(child, x, y, z);
        }
      }
    }
  }


  loadPose(poseData, bones) {
    let L, R = false;
    for (let i = 0; i < bones.length; i++) {
      let bone = bones[i].bone;
  
      if (!poseData[bone]) {
        console.warn(`No pose data found for bone: ${bone}`);
        continue;
      }
  
      // If the bone is "Root_Main", handle position, otherwise handle rotation
      if (bone === "Root_Main") {
        window.changePosition(bone, poseData[bone].x, poseData[bone].y, poseData[bone].z);
  
        // Update the state with the new position values
        if (this.state[bone]) {
          this.state[bone].x = poseData[bone].x;
          this.state[bone].y = poseData[bone].y;
          this.state[bone].z = poseData[bone].z;
        }
      } else {
        window.changeRotation(bone, poseData[bone].x, "x");
        window.changeRotation(bone, poseData[bone].y, "y");
        window.changeRotation(bone, poseData[bone].z, "z");
  
        // Update the state with the new rotation values
        if (this.state[bone]) {
          this.state[bone].x = poseData[bone].x;
          this.state[bone].y = poseData[bone].y;
          this.state[bone].z = poseData[bone].z;
        }
      }
  
      this.scene.updateMatrixWorld();
  
      // Check if the bone has a matching parent bone in the `trans` object
      let parentBoneName = bone.substring(0, bone.lastIndexOf("_"));
      if (parentBoneName in this.state.trans) {
        let parentBone = this.scene.getMyObjectByName(parentBoneName);
        if (parentBone instanceof THREE.Bone) {
          // Retrieve the parent bone's rotation
          let parentRotation = parentBone.rotation;
          // Apply the parent bone's rotation to the child bone
          window.changeRotation(bone, parentRotation.x, "x");
          window.changeRotation(bone, parentRotation.y, "y");
          window.changeRotation(bone, parentRotation.z, "z");
          
          // Apply the parent bone's position to the child bone
          let parentPosition = parentBone.position;
          window.changePosition(bone, parentPosition.x, parentPosition.y, parentPosition.z);
          
          // Update the state with the new position values
          if (this.state[bone]) {
            this.state[bone].x = parentPosition.x;
            this.state[bone].y = parentPosition.y;
            this.state[bone].z = parentPosition.z;
          }
        }
      }
  
      if (bone === "LeftToeBase" || bone === "LeftToeBase_Head" || bone === "LeftToeBase_Chest" || bone === "LeftToeBase_PadL" || bone === "LeftToeBase_PadR") {
        L = true;
        if (L && R) {
          this.placeStand();
        }
      }
      if (bone === "RightToeBase" || bone === "RightToeBase_Head" || bone === "LeftToeBase_Chest" || bone === "LeftToeBase_PadL" || bone === "LeftToeBase_PadR") {
        R = true;
        if (L && R) {
          this.placeStand();
        }
      }
    }
  };
  

  loadPoseold(poseData, bones) {
    let L, R = false;
    for (let i = 0; i < bones.length; i++) {
      let bone = bones[i].bone;

      if (!poseData[bone]) {
        console.warn(`No pose data found for bone: ${bone}`);
        continue;
      }

      window.changeRotation(bone, poseData[bone].x, "x");
      window.changeRotation(bone, poseData[bone].y, "y");
      window.changeRotation(bone, poseData[bone].z, "z");

      // Update the state with the new rotation values
      if (this.state[bone]) {
        this.state[bone].x = poseData[bone].x;
        this.state[bone].y = poseData[bone].y;
        this.state[bone].z = poseData[bone].z;
      }

      this.scene.updateMatrixWorld();

      // Check if the bone has a matching parent bone in the `trans` object
      let parentBoneName = bone.substring(0, bone.lastIndexOf("_"));
      if (parentBoneName in this.state.trans) {
        let parentBone = this.scene.getMyObjectByName(parentBoneName);
        if (parentBone instanceof THREE.Bone) {
          // Retrieve the parent bone's rotation
          let parentRotation = parentBone.rotation;
          // Apply the parent bone's rotation to the child bone
          window.changeRotation(bone, parentRotation.x, "x");
          window.changeRotation(bone, parentRotation.y, "y");
          window.changeRotation(bone, parentRotation.z, "z");
        }
      }

      if (bone === "LeftToeBase" || bone === "LeftToeBase_Head" || bone === "LeftToeBase_Chest" || bone === "LeftToeBase_PadL" || bone === "LeftToeBase_PadR") {
        L = true;
        if (L && R) {
          this.placeStand();

        }
      }
      if (bone === "RightToeBase" || bone === "RightToeBase_Head" || bone === "LeftToeBase_Chest" || bone === "LeftToeBase_PadL" || bone === "LeftToeBase_PadR") {
        R = true;
        if (L && R) {
          this.placeStand();

        }
      }
    }
  };


  save(blob, filename) {
    this.link.href = URL.createObjectURL(blob);
    this.link.download = filename || "untitled.json";
    this.link.click();
  }

  saveArrayBuffer(buffer, filename) {
    this.save(new Blob([buffer], { type: "application/octet-stream" }), filename);
  }

  saveString(text, filename) {
    this.save(new Blob([text], { type: "text/plain" }), filename);
  }

  exportToSTL(name) {

    let exporter = new MinSTLExporter();

    if (name) {
      this.saveString(exporter.parse(this.group), name + ".stl");

    } else {
      let stlList = [];
      // I need to know in which order the files are exported...
      let Meshes = [
        "mesh-stand",
        "mesh-body",
        "mesh-head",
        "mesh-arm-l",
        "mesh-arm-r",
        "mesh-foot-l",
        "mesh-foot-r",
        "mesh-hand-l",
        "mesh-hand-r",
        "mesh-padl",
        "mesh-padr",
        "mesh-chest"

      ];
      for (let i = 0; i < Meshes.length; i++) {
        this.group.traverse(function (child) {
          if (child.name === Meshes[i]) {
            stlList.push(exporter.parse(child))
          }
        });
      }

      return stlList;
    }
  };

  saveScreenshot(name) {
    var a = document.createElement('a');
    this.renderer.render(this.scene, this.camera);
    a.href = this.renderer.domElement.toDataURL().replace("image/png", "image/octet-stream");
    a.download = name + '.png';
    a.click();
  };

  move() {
    this.camera.position += 10;
  }


  componentDidMount() {
    //const trans = require('../library/poses/translate.json');

    for (let i = 0; i < bones.length; i++) {
      let bone = bones[i].bone;
      this.setState({ [bone]: window.getRotation(bone) })
    }

  }

  exportPose2() {
    let mesh = this.scene.getObjectByName("mesh-body"); // Assuming `this.scene` is your Three.js scene
    if (!mesh || !mesh.skeleton) {
      console.warn('No skeleton found for "mesh-body"');
      return;
    }

    let model = {};
    for (let i = 0; i < mesh.skeleton.bones.length; i++) {
      let bone = mesh.skeleton.bones[i];
      // Convert the quaternion to Euler angles (in degrees)
      let euler = new THREE.Euler().setFromQuaternion(bone.quaternion, 'XYZ');
      model[bone.name] = {
        x: THREE.MathUtils.radToDeg(euler.x),
        y: THREE.MathUtils.radToDeg(euler.y),
        z: THREE.MathUtils.radToDeg(euler.z)
      };
    }

    let model_json_str = JSON.stringify(model, null, 2); // set indentation level to 2 spaces
    let element = document.createElement("a");
    let file = new Blob([model_json_str], { type: "application/json" });
    element.href = URL.createObjectURL(file);
    element.download = "CUSTOM.json";
    element.click();
  }


  exportPose() {
    for (let i = 0; i < bones.length; i++) {
      let bone = bones[i].bone;
      model[bone] = this.state[bone];
    }
    let model_json_str = JSON.stringify(model, null, 2); // set indentation level to 2 spaces
    let element = document.createElement("a");
    let file = new Blob([model_json_str], { type: "application/json" });
    element.href = URL.createObjectURL(file);
    element.download = "CUSTOM.json";
    element.click();
  }

  loadSkybox() {
    console.log('Load Sky');
  }

  changeSkybox(file) {
    console.log('Change Sky Name');
    let path = `https://citizenzero.s3.us-west-1.amazonaws.com/skybox/${file}/`;
    let extension = ".jpg";
    let urls = [
      path + "px" + extension,
      path + "nx" + extension,
      path + "py" + extension,
      path + "ny" + extension,
      path + "pz" + extension,
      path + "nz" + extension
    ];

    let reflectionCube = new THREE.CubeTextureLoader().load(urls);
    reflectionCube.format = THREE.RGBAFormat;
    reflectionCube.mapping = THREE.CubeRefractionMapping;
    reflectionCube.TextureEncoding = THREE.SRGBColorSpace; // Add this line

    // Set the environment for the scene
    this.renderer.TextureEncoding = THREE.SRGBColorSpace;
    this.scene.environment = reflectionCube;
    this.scene.background = reflectionCube;
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = Math.pow(0.9, 5.0);

    this.group.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        //child.material.color.set(color);
        //child.material.color.convertSRGBToLinear();
        child.material.envMap = this.scene.environment;
      }
    });
  }

  removeAllArmor() {
    const meshTypesToRemove = ["Head", "Chest", "PadL", "PadR", "HandL", "HandR", "ArmL", "ArmR", "FootL", "FootR", "LegL", "LegR", "Waist", "Back"];

    meshTypesToRemove.forEach((type) => {
      const meshName = this.loadedMeshes[type]?.name;
      if (meshName) {
        const mesh = this.scene.getMyObjectByName(`mesh-${type}`);
        if (mesh) {
          this.group.remove(mesh);
          mesh.geometry.dispose();
          mesh.material.dispose();
        }
        this.loadedMeshes[type].name = undefined;
      }
    });
  }





}

export default MainStage;