// src/components/ColorWheel.js
import React from 'react';
import { HexColorPicker } from 'react-colorful';
// Removed: import 'react-colorful/dist/index.css';
import '../css/ColorWheel.css'; // Ensure this CSS file exists for your custom styles

const ColorWheel = ({ color, onChange, onClose }) => {
  return (
    <div className="color-wheel-overlay">
      <div className="color-wheel-container">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <HexColorPicker color={color} onChange={onChange} />
      </div>
    </div>
  );
};

export default ColorWheel;
