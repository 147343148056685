import React, { useState, useRef } from 'react';
import './factoryItemCardSCSS.scss';

function FactoryItemCard(props) {
  const {
    saleItem,
    category,
    isLeft,
    bones,
    applyPose,
    updateMeshes,
    loadedMeshes,
    updateLoading,
    customPoseData,
    updatePopupMessage,
    updatePopup,
    fetchAndUpdateLibraries,
    pose,
  } = props;

  // Refs and state for 3D effect
  const cardRef = useRef(null);
  const [tiltStyle, setTiltStyle] = useState({});
  const styleTag = useRef(null);

  const handleMouseMove = (e) => {
    const card = cardRef.current;
    const cardRect = card.getBoundingClientRect();

    const centerX = cardRect.left + cardRect.width / 2;
    const centerY = cardRect.top + cardRect.height / 2;

    const mouseX = e.clientX - centerX;
    const mouseY = e.clientY - centerY;

    // Calculate tilt angles
    const rotateX = (mouseY / cardRect.height) * 30;
    const rotateY = -(mouseX / cardRect.width) * 30;

    setTiltStyle({
      transform: `translateX(-50%) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
    });

    // Holographic effect based on mouse position
    const px = Math.abs(Math.floor((100 / cardRect.width) * mouseX) - 100);
    const py = Math.abs(Math.floor((100 / cardRect.height) * mouseY) - 100);
    const lp = 0 + (px - 50) / 1.6;
    const tp = 0 + (py - 50) / 1.6;
    const px_spark = 50 + (px - 50) / 7;
    const py_spark = 50 + (py - 50) / 7;
    const p_opc = 20 + Math.abs(50 - px + (50 - py)) * 1.5;

    // Set dynamic styles for :before and :after pseudo-elements
    const styleContent = `
      .factoryItemCard:hover::before {
        background-position: ${lp}% ${tp}%;
      }
      .factoryItemCard:hover::after {
        background-position: ${px_spark}% ${py_spark}%;
        opacity: ${p_opc / 100};
      }
    `;
    if (styleTag.current) {
      styleTag.current.innerHTML = styleContent;
    }
  };

  const handleMouseLeave = () => {
    setTiltStyle({
      transform: `translateX(-50%) rotateX(0deg) rotateY(0deg)`,
      transition: 'transform 0.5s ease',
    });

    // Clear the styles when mouse leaves
    if (styleTag.current) {
      styleTag.current.innerHTML = '';
    }
  };

  const handleClick = () => {
    let meshType;
    switch (category) {
      case 'body':
        meshType = 'Body';
        break;
      case 'chest':
        meshType = 'Chest';
        break;
      case 'head':
        meshType = 'Head';
        break;
      case 'pad':
        meshType = isLeft ? 'PadL' : 'PadR';
        break;
      case 'hand':
        meshType = isLeft ? 'HandL' : 'HandR';
        break;
      case 'arm':
        meshType = isLeft ? 'ArmL' : 'ArmR';
        break;
      case 'foot':
        meshType = isLeft ? 'FootL' : 'FootR';
        break;
      case 'leg':
        meshType = isLeft ? 'LegL' : 'LegR';
        break;
      case 'podium':
        meshType = 'Podium';
        break;
      case 'prop01':
        meshType = 'Prop01';
        break;
      case 'prop02':
        meshType = 'Prop02';
        break;
      case 'prop03':
        meshType = 'Prop03';
        break;
      case 'skybox':
        meshType = 'skybox';
        break;
      default:
        meshType = undefined;
    }

    if (saleItem.premium) {
      updatePopupMessage(
        'Item is locked and might be available at a later time. No promises though.'
      );
      updatePopup(true);
    } else {
      if (category === 'pose') {
        applyPose(saleItem.file);
      } else if (category === 'stand') {
        window.changeStand(saleItem.file);
      } else if (category === 'podium') {
        window.changePodium(saleItem.file);
      } else if (category === 'body') {
        window.removeAllArmor();
        let newEndpoint;
        let newPose;

        switch (saleItem.bid) {
          case 'earthborn_m':
            newEndpoint = 'https://czbackend.herokuapp.com/api/eb_m';
            newPose = 'action';
            break;
          case 'earthborn_f':
            newEndpoint = 'https://czbackend.herokuapp.com/api/eb_f';
            newPose = 'ebf';
            break;
          case 'dynalith':
            newEndpoint = 'https://czbackend.herokuapp.com/api/dynalith';
            newPose = 'dyna2';
            break;
          default:
            newEndpoint = 'https://czbackend.herokuapp.com/api/eb_m';
            newPose = 'Default';
        }

        fetchAndUpdateLibraries(newEndpoint);
        updateLoading(true);

        window.changeMesh(
          category,
          saleItem,
          isLeft,
          bones,
          pose,
          customPoseData
        );

        let updatedMeshes = { ...loadedMeshes };
        updatedMeshes['Body'] = saleItem.file;
        updateMeshes(updatedMeshes);

        setTimeout(() => {
          fetchAndUpdateLibraries(newEndpoint);
          applyPose(newPose);
        }, 1000);
      } else if (category === 'env') {
        window.changeEnv(saleItem.file);
      } else if (category === 'prop01' || category === 'prop02' || category === 'prop03') {
        window.changeProp(saleItem.file, meshType);
      } else if (category === 'skybox') {
        window.changeSkybox(saleItem.file);
      } else {
        updateLoading(true);
        window.changeMesh(
          category,
          saleItem,
          isLeft,
          bones,
          pose,
          customPoseData
        );
        let updatedMeshes = { ...loadedMeshes };
        updatedMeshes[meshType] = saleItem.file;
        updateMeshes(updatedMeshes);
      }
    }
  };

  return (
    <>
      <style ref={styleTag}></style> {/* Dynamically updates styles for :before and :after */}
      <div className="col">
        <div className="factoryItemContainer" ref={cardRef}>
          <div
          className="factoryItemCard"
          title={saleItem.description}
            onClick={handleClick}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={tiltStyle}
          >
            <div className="factoryItemFeature">{saleItem.category}</div>
            <div className="imageWrapper">
            <img
                src={
                  category === 'skybox'
                    ? `https://citizenzero.s3.us-west-1.amazonaws.com/skybox/${saleItem.file}/thumb.png`
                    : `https://citizenzero.s3.us-west-1.amazonaws.com/thumbs/${category}/${saleItem.img}`
                }
                alt={saleItem.img}
                className="img-fluid"
              />
            </div>
            <div className="itemDetails">
              <h4 className="itemTitle">{saleItem.name}</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FactoryItemCard;
